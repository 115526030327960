'use strict';

angular.module('enervexSalesappApp').factory('Attachment', function ($resource) {
	return $resource('/api/accounts/:accountId/jobs/:jobId/attachments/:id/:versions', {
		id: '@_id'
	},
	{
		save: {
			method: 'POST',
			transformRequest: angular.identity,
			headers: {
				'Content-Type': undefined,
				'Accept': "application/json"
			}
		} ,
		update: {
			method: 'PUT',
			transformRequest: angular.identity,
			headers: {
				'Content-Type': undefined,
				'Accept': "application/json"
			}
		},
		versions: {
			method: 'GET',
			isArray: true,
			params: {
				accountId: '@accountId',
				jobId: '@jobId',
				id: '@attachmentId',
				versions: 'versions'
			}
		}
	});
});
